.appSidebar {
  background: $color-white;
  border-right: 1px solid $color-gray-1;
  width: 100%;
  height: 100vh;
  padding: 24px 16px;
  position: relative;
  animation: all 0.2s ease-in-out;
  &.active {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      svg {
        display: none;
      }
      span {
        svg {
          display: block;
          transform: rotate(-180deg);
        }
      }
    }
    nav {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      a {
        font-size: 0;
        padding: 5px;
        grid-gap: 0;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .logoutBtn {
      width: 30px;
      height: 30px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $color-gray-1;
      background: $color-white;
      cursor: pointer;
      svg {
        transition: all 0.2s ease-in-out;
      }
    }
  }
  nav {
    margin-top: 35px;
    a {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      border-radius: 4px;
      padding: 10px;
      &.active {
        background: $color-success-1;
      }
    }
  }
  .logoutBtn {
    position: absolute;
    left: 16px;
    bottom: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
