.loginPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 50%;
    max-width: 400px;
    border: 1px solid $color-gray-1;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
    h1 {
      font-weight: 500;
      font-size: 28px;
    }

    h2 {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-text-3;
    }

    .input-field {
      margin-bottom: 12px;
    }

    button {
      margin-top: 24px;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 16px;
    }
  }
}
