.signInContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    width: 500px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px;
    h2 {
      text-align: center;
      margin-bottom: 32px;
      font-weight: 500;
    }
    button {
      margin-top: 32px;
    }
  }
}
