button,
a {
  cursor: pointer;
  border: 0;
  &.contained {
    width: 100%;
    background: $color-success-2;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    &:hover {
      background: #75ead3;
    }
    &:active {
      background: #36bebc;
    }
    &:disabled {
      border: 0;
    }
    &.dark {
      background: $color-black;
    }
    &.danger {
      background: $color-danger;
      color: $color-white;
    }
    &.cancel {
      background: $color-gray-1;
    }
  }
  &.outlined {
    background: $color-white;
    border: 1px solid $color-black;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    &:hover {
      background: $color-black;
      color: $color-white;
      svg {
        path {
          fill: $color-white;
        }
      }
    }
    &.danger {
      color: $color-danger;
      border: 1px solid $color-danger;
      &:hover {
        background: rgba($color: $color-danger, $alpha: 0.2);
      }
    }
    &:disabled {
      border: 1px solid $color-gray-1;
    }
  }
  &.rounded {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-gray-1;
    background: $color-white;
    border-radius: 50%;
    &:hover {
      background: $color-gray-1;
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 24px;
      height: 24px;
    }
    &.lg {
      width: 30px;
      height: 30px;
      padding: 0;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  &.xs {
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
  }
  &.sm {
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    width: fit-content;
  }
  &.lg {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
  }
  &.auto-width {
    width: fit-content;
  }
  &:disabled {
    background: $color-gray-1 !important;
    cursor: not-allowed !important;
  }
  &.wFull {
    width: 100% !important;
  }
}
