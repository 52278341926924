.badge {
  padding: 4px 8px;
  border-radius: 32px;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  width: fit-content;
  text-transform: capitalize;
  svg {
    width: 16px;
    height: 16px;
  }
  &.success,
  &.DELIVERED,
  &.CONNECTED {
    background: $color-success-1;
    border-color: $color-success-2;
  }
  &.secondary,
  &.DISCONNECTED,
  &.UNKNOWN {
    background: $color-gray-3;
    border-color: $color-gray-1;
  }
  &.CONNECTING {
    background: #ffeb3b7d;
    border-color: #f2e03a;
  }
  &.danger,
  &.FAILURE,
  &.RETURNED {
    background: #fdccc5;
    border-color: $color-danger;
  }
  &.TRANSIT,
  &.PRE_TRANSIT {
    background: #ffcea1;
    border-color: $color-warning;
  }

  &.badgePay {
    position: relative;
    &:hover {
      .badge-abs {
        display: block;
      }
    }
    .badge-abs {
      position: absolute;
      display: none;
      top: calc(100% + 4px);
      right: 0;
      border: 1px solid $color-gray-1;
      border-radius: 8px;
      white-space: nowrap;
      padding: 8px 16px;
      background: $color-white;
      z-index: 5;
    }
  }
}

.orderItemStatus {
  text-transform: capitalize;
  color: $color-black;
  &.unshipped,
  &.backordered {
    color: $color-danger;
  }
  &.shipped,
  &.delivered {
    color: $color-success-2;
  }
  &.processing {
    color: $color-warning;
  }
  &.cancelled {
    color: $color-text-2;
  }
}

.badge-rounded {
  padding: 3px 6px;
  width: fit-content;
  font-weight: 400;
  background-color: #fff1e3;
  color: #ff9f43;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  &.success {
    background-color: #dff7e9;
    color: #28c76f;
  }
  &.danger {
    background-color: #fce5e6;
    color: #ea5455;
  }
}
