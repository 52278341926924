.suppliersPage {
  .paper {
    .header {
      border-color: $color-dark;
      .hero {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .search {
          width: 250px;
          display: flex;
          align-items: center;
          position: relative;
          .searchIcon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
            width: 16px;
            height: 16px;
            path {
              fill: $color-text-2;
            }
          }
          input {
            border: 1px solid $color-dark;
            width: 100%;
            padding: 12px 16px 12px 36px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 20px;
          }
        }
        
      }
    }
  }
  .table {
    .noResults {
      text-align: center;
      padding: 32px;
      color: $color-text;
      font-size: 14px;
    }
    .row {
      display: grid;
      align-items: center;
      grid-gap: 10px;
      grid-template-columns: 2fr 1fr 1fr 110px 110px 110px 120px 90px;
      border-bottom: 1px solid $color-dark;
      &:last-child {
        border-bottom: none;
      }
      &.head {
        text-transform: uppercase;
      }
      .cell {
        padding: 10px;
        font-size: 14px;
        line-height: 20px;
        color: $color-text;

        .img {
          width: 100px;
          height: 50px;
          mix-blend-mode: multiply;
          border: 1px solid $color-dark;
          border-radius: 8px;
        }

        &.center {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
  .modal {
    position: fixed;
    inset: 0;
    background: #f8f7fa;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .hero {
      border-bottom: 1px solid $color-dark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      h2 {
        font-weight: 400;
        font-size: 20px;
      }
      .right {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        button {
          width: fit-content;
        }
      }
    }
    .modalOuter {
      padding: 16px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .paper {
        box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
        .header {
          padding: 16px 16px 8px 16px;
          border-bottom: 0;
          h2 {
            color: #5d596c;
            font-weight: 400;
            font-size: 1.125rem;
          }
        }
        .body {
          padding: 16px;
          display: flex;
          flex-direction: column;
          grid-gap: 16px;
        }
      }
    }
  }
}
