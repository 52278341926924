.singleGpoPageWrapper {
  .flexTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .row {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  h1 {
    font-size: 20px;
    span {
      font-weight: 400;
      color: $color-text-3;
    }
  }
  .gpoNameSubtitle {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    font-size: 28px;
    margin: 12px 0 16px 0;
    img {
      object-fit: contain;
    }
  }

  .gpoTable {
    border-radius: 8px;
    border: 1px solid $color-gray-1;
    background: $color-white;
    margin-bottom: 16px;
    .gpoTableHero {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray-1;
      .left {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        h3 {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    .gpoTableBody {
      .row {
        padding: 0 10px;
        align-items: center;
        grid-gap: 16px;
        border-bottom: 1px solid $color-gray-1;
        display: grid;
        &:last-child {
          border-bottom: 0;
        }

        .customCell {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 8px 0;

          &.relativeCont {
            position: relative;

            ul {
              position: absolute;
              border-radius: 6px;
              left: 10px;
              padding: 6px;
              background-color: white;
              display: flex;
              flex-direction: column;
              gap: 8px;
              z-index: 99999999999;

              li {
                padding: 6px 8px;
              }
            }
          }
        }

        .cell {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 8px 0;

          &.linkCell {
            text-decoration: underline;
          }

          &.flex {
            display: flex;
            align-items: center;
            grid-gap: 8px;
          }
        }
        &.headRow {
          border-bottom: 1px solid $color-gray-1;
          .cell {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: $color-text-3;
          }
        }
      }
      &.members {
        .row {
          grid-template-columns: 1fr 1fr 1fr 160px;
          button {
            padding: 8px;
            font-size: 12px;
          }
        }
      }
      &.partners {
        .row {
          grid-template-columns: 1fr 1fr 1fr 1fr 120px;
          img {
            object-fit: contain;
          }
        }
      }
    }
    .noDataParagraph {
      text-align: center;
      margin: 24px 0;
    }
  }
}

.deleteCredentials {
  .modalBox {
    width: 420px;
    .modalBody {
      h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 18px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 24px;
      }
      .buttons {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        button {
          padding: 12px;
          font-size: 16px;
        }
      }
    }
  }
}

.gpoAlertModal {
  .modalBox {
    width: 420px;
    .modalBody {
      h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 24px;
        text-align: center;
      }
      .buttons {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        button {
          padding: 12px;
          font-size: 16px;
        }
      }
    }
  }
}

.modal {
  &.createNewPartnership {
    .modalBox {
      .modalBody {
        padding-top: 0;
        padding-inline: 0;

        .scrollable {
          max-height: 650px;
          overflow-y: auto;
          padding-inline: 18px;

          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-track {
            background: $color-gray-3;
            border-radius: 16px;
          }
          &::-webkit-scrollbar-thumb {
            background: $color-gray-4;
            border-radius: 16px;
          }
        }
        nav {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin: 24px 0 16px 0;
          grid-gap: 8px;
          p {
            width: 100%;
            text-align: center;
            background: $color-gray-1;
            border-radius: 8px;
            padding: 8px;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            &.active {
              background: $color-success-1;
            }
          }
        }
        .select {
          .selectPreview {
            padding-top: 0;
            padding-bottom: 0;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 8px;
            input {
              padding-left: 0;
              padding-right: 0;
              background: $color-white;
            }
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
        .added {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .paper {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            svg {
              cursor: pointer;
              width: 18px;
              height: 18px;
            }

            .col {
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
          }
        }

        .addRep {
          margin-top: 18px;
        }

        .contained {
          width: calc(100% - 36px);
          margin-inline: auto;
        }
      }
    }
  }
}

.addNewMemberModal {
  .modalBox {
    width: 700px;
    .modalBody {
      .listOfOrgs {
        .tableSearch {
          input {
            border: 1px solid $color-gray-1;
            border-radius: 8px;
          }
        }
        .allOrgs {
          height: calc(100vh - 470px);
          overflow-y: auto;
          margin: 12px 0;
          &::-webkit-scrollbar {
            width: 0;
          }
          li {
            display: grid;
            grid-template-columns: 1fr 140px;
            align-items: center;
            grid-gap: 16px;
            padding: 8px 0;
            border-bottom: 1px solid $color-gray-1;
            height: 55px;
            &:last-child {
              border-bottom: 0;
            }
            a {
              font-size: 14px;
              font-weight: 400;
              text-decoration: underline;
              width: fit-content;
            }
            button {
              padding: 8px;
              font-size: 14px;
              font-weight: 500;
              width: 100%;
            }
          }
        }
        .tableLegend {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          .tablePerPage {
            display: flex;
            align-items: center;
            grid-gap: 8px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
          .tablePagination {
            margin-left: auto;
          }
        }
      }
    }
  }
}
