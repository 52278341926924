.switchBtn {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 500;
  }
  .buffer {
    width: 40px;
    height: 25px;
    border-radius: 32px;
    border: 1px solid rgb(234, 236, 244);
    display: flex;
    align-items: center;
    padding: 4px;
    background: $color-white;
    transition: all 0.2s ease-in-out 0s;
    position: relative;
    &.active {
      background: rgba(213, 251, 229, 0.48);
      border: 1px solid rgb(54, 190, 188);
      &::after {
        left: calc(100% - 18px);
        background: rgb(54, 190, 188);
      }
    }
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: rgb(217, 219, 221);
      transition: all 0.2s ease-in-out;
      position: absolute;
      left: 4px;
    }
  }
}
