$color-white: #fff;
$color-black: #000;
$color-danger: #ef5628;
$color-warning: #ff9532;
$color-success: #23b95e;

$color-text-1: #080c2d;
$color-text-2: #9c9fad;
$color-text-3: #676c88;

$color-gray-1: #eaecf4;
$color-gray-2: #f9fbfc;
$color-gray-3: #f3f6f9;
$color-gray-4: #d3d6e2;

$color-success-1: #d5fbe5;
$color-success-2: #4addce;
$color-success-3: #36bebc;

$color-dark: #dbdade;
$color-text: #6f6b7d;
$color-secondary: #a5a3ae;
