.userPage {
  .fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }

  .userOrganizations {
    margin-top: 24px;
    .blocks {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
      .block {
        border: 1px solid $color-gray-1;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: translateY(2px);
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
        }
        .head {
          padding: 16px;
          border-bottom: 1px solid $color-gray-1;
        }
        p {
          padding: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
