.autoCartQueuePage {
  .autoCartQueueTables {
    .sectionHeader {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      gap: 18px;
      h1 {
        font-size: 24px;
        font-weight: 500;
      }

      .outlined {
        padding: 8px 12px;
      }

      .tableSearch {
        input {
          width: 300px;
        }
      }
    }

    .tables {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .orgName {
        padding: 16px 0 0 16px;
        font-size: 19px;
      }

      .supplierGroup {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        border: 1px solid $color-text;
        border-radius: 6px;
      }

      .bySupplierTable {
        border: 1px solid $color-dark;
        border-radius: 8px;
        margin: 16px 16px 0 16px;
      }
    }
  }

  .centeredContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}
