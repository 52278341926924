.siteLocker {
  position: fixed;
  inset: 0;
  background: $color-success-1;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    background: $color-white;
    border-radius: 20px;
    padding: 24px;
    width: 500px;
    border: 1px solid $color-gray-1;
    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 24px;
    }
    p {
      margin: 8px 0 24px 0;
      text-align: center;
      font-size: 16px;
      color: $color-text;
    }
    button {
      margin-top: 32px;
    }
  }
}
