.inputRadio {
  width: 20px;
  height: 20px;
  border: 1px solid $color-gray-1;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  &.active {
    border-color: $color-success-1;
    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      background: $color-success-1;
      border-radius: 50%;
    }
  }
}

.input-field {
  label {
    display: block;
    margin-bottom: 4px;
    padding: 0;
    color: #5d596c;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid $color-dark;
    color: $color-text;
    font-size: 14px;
    line-height: 20px;
    background: $color-white;
    font-weight: 400;
    border-radius: 8px;
    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      color: #a8aaae;
      font-weight: 400;
    }
  }

  .errorMsg {
    color: $color-danger;
    font-size: 12px;
    line-height: 20px;
    margin-top: 3px;
  }
}

.searchInput {
  width: 250px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }
  input {
    width: 100%;
    padding: 8px 16px 8px 36px;
    border-radius: 8px;
    border: 1px solid $color-gray-1;
    background: $color-white;
    font-size: 14px;
    line-height: 20px;
    &::placeholder {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
