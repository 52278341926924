.counter {
  display: grid;
  grid-template-columns: 35px 45px 35px;
  grid-template-rows: 36px;
  border: 1px solid $color-gray-1;
  border-radius: 8px;
  background: $color-white;
  overflow: hidden;
  div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $color-gray-3;
    }
    &.minus {
      &::after {
        content: '';
        width: 10px;
        height: 2px;
        background: $color-black;
      }
    }
    &.plus {
      &::before {
        content: '';
        width: 10px;
        height: 2px;
        background: $color-black;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 2px;
        transform: translate(-50%, -50%) rotate(90deg);
        background: $color-black;
      }
    }
  }
  input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 16px;
    border: 0;
    border-left: 1px solid $color-gray-1;
    border-right: 1px solid $color-gray-1;
    padding: 0 4px;
  }
}
