.orgsPage {
  .flexBetween {
    margin-bottom: 24px;
  }
}

.newOrganizationModal {
  .modalBox {
    width: 500px;
    .modalBody {
      .fields {
        margin-bottom: 24px;
      }
    }
  }
}
