* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  outline: none !important;
}

body {
  overflow: hidden;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-text-1;
}

a {
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: none;
  font-weight: 400;
  font-style: normal;
}

input,
textarea,
button {
  font-family: 'Lexend', sans-serif;
  color: $color-text-1;
}

input,
textarea {
  color: $color-text-1;
  &::placeholder {
    color: $color-text-2;
  }
}

ul,
ol,
li {
  list-style-type: none;
}

.withoutScrollbar {
  &::-webkit-scrollbar {
    width: 0;
  }
}

.customScrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: $color-gray-3;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-gray-4;
    border-radius: 16px;
  }
}

.pageWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 224px 1fr;
  &.active {
    grid-template-columns: 60px 1fr;
  }
  .appHolder {
    height: 100vh;
    background: $color-gray-2;
    padding: 16px 4px 16px 16px;
    .pageHolder {
      height: 100%;
      overflow-y: auto;
      padding-right: 20px;
    }
  }
}

h1.pageTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  &.center {
    text-align: center;
  }
}

label {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-text-3;
  padding: 10px 0;
  display: block;
}

textarea {
  border: 1px solid $color-gray-1;
  width: 100%;
  height: 100px;
  border-radius: 8px;
  resize: none;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.paper {
  width: 100%;
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
  .paperHeader {
    padding: 10px;
    border-bottom: 1px solid $color-gray-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      grid-gap: 4px;
      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: $color-gray-1;
        }
        svg {
          width: 22px;
          height: 22px;
          cursor: grab;
        }
      }
    }
  }
  .paperBody {
    padding: 10px;
  }
}

.fieldsRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.field {
  position: relative;
  input {
    width: 100%;
    padding: 10px 16px;
    background: $color-gray-3;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    border: 0;
  }

  .fieldPhoneInput {
    margin-top: 16px;
    font-size: 14px;
    color: #676c88;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 500;
  }

  .date-picker-relative {
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    z-index: 99;
    input {
      background: $color-white;
    }
    .rdrInputRangeInput {
      padding: 0;
      width: 30px;
      border: 1px solid $color-gray-1;
    }
  }
}

.fieldError {
  font-size: 14px;
  line-height: 20px;
  color: $color-danger;
  font-weight: 300;
  margin-top: 3px;
}

.orderStatus {
  &.delivered,
  &.confirmed,
  &.shipped,
  &.placed {
    color: $color-success;
  }
  &.canceled {
    color: $color-text-3;
  }
  &.pending,
  &.partially {
    color: #dcc709;
  }
  &.declined,
  &.refunded,
  &.returned {
    color: $color-danger;
  }
}

.orderStatusBG {
  &.delivered,
  &.confirmed,
  &.shipped {
    background: $color-success-1;
    border-color: $color-success-2;
  }
  &.canceled {
    background: $color-gray-1;
    border-color: #d0d0d0;
  }
  &.pending {
    background: #ffeb3b7d;
    border-color: #f2e03a;
  }
  &.declined,
  &.refunded,
  &.returned {
    background: #fdccc5;
    border-color: #f89898;
  }
}

.supplierLogoBox {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid $color-gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.pac-container {
  z-index: 99999999999 !important;
}
