.newPaymentMethodModal {
  .modalBox {
    width: 600px;
    .modalBody {
      .stripeForm {
        .fieldsWrap {
          .fieldsRow {
            display: grid;
            grid-template-columns: 1fr 116px 116px;
            grid-gap: 16px;
            .stripeField {
              font-size: 14px;
              line-height: 20px;
              background: $color-gray-3;
              border-radius: 8px;
              padding: 10px 16px;
              border: 0;
            }
          }
        }
        .assignOffice {
          margin: 24px 0;
          h2 {
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
          }
          .search {
            position: relative;
            margin: 12px 0;
            input {
              width: 100%;
              background: $color-white;
              border-radius: 8px;
              padding: 10px 16px 10px 46px;
              border: 1px solid $color-gray-1;
              font-size: 16px;
              line-height: 24px;
            }
            svg {
              position: absolute;
              top: 50%;
              left: 16px;
              transform: translateY(-50%);
              width: 16px;
              height: 16px;
              path {
                fill: $color-text-2;
              }
            }
          }
          .alertBox {
            background: $color-gray-3;
            border-radius: 8px;
            padding: 12px;
            .checkboxRow {
              display: flex;
              align-items: center;
              grid-gap: 8px;
              cursor: pointer;
              p {
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
              }
            }
          }
          .noOffices {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            padding: 12px 0;
            font-weight: 500;
          }
          ul {
            display: flex;
            flex-direction: column;
            grid-gap: 16px;
            margin-top: 24px;
            max-height: 185px;
            overflow-y: auto;
            padding: 10px 0 10px 10px;
            li {
              border: 1px solid $color-gray-1;
              border-radius: 8px;
              padding: 16px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              cursor: pointer;
              margin-right: 8px;
              transition: all 0.2s ease-in-out;
              &.active {
                border-color: $color-success-3;
                .circle {
                  border-color: $color-success-3;
                  svg {
                    opacity: 1;
                  }
                }
              }
              .circle {
                position: absolute;
                top: -10px;
                left: -10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $color-gray-1;
                background: $color-white;
                svg {
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                }
              }
              h3 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
              }
              p {
                font-size: 14px;
                line-height: 20px;
                color: $color-text-2;
              }
            }
          }
        }
      }
    }
  }
}
