.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
}

.flexAlign {
  display: flex;
  align-items: center;
}

.fz14 {
  font-size: 14px;
  line-height: 20px;
}

.colorSuccess {
  color: $color-success;
}

.colorDanger {
  color: $color-danger;
}

.space {
  margin-bottom: 16px;
}

.gg8 {
  grid-gap: 8px;
}

.gg16 {
  grid-gap: 16px;
}

.w-fit {
  width: fit-content;
}

.buttonsx2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  button {
    width: 100%;
  }
}

.bbGray {
  border-bottom: 1px solid $color-gray-3;
}

.pb12 {
  padding-bottom: 12px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt24 {
  margin-top: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.mt16 {
  margin-top: 16px;
}

.mx24 {
  margin: 24px 0;
}

.mlAuto {
  margin-left: auto;
}

.m16 {
  margin: 16px 0;
}

.officeField {
  &.active {
    pointer-events: none;
  }
}

.cur-pointer {
  cursor: pointer;
}

.errorFieldMsg {
  color: $color-danger;
  font-size: 14px;
  margin-top: 8px;
}

.tablePagination {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  .arrow {
    width: 30px;
    height: 30px;
    background: $color-white;
    border: 1px solid $color-gray-1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $color-gray-3;
    }
    &.prev {
      transform: rotate(90deg);
    }
    &.next {
      transform: rotate(-90deg);
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .item {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    background: $color-white;
    border-radius: 50%;
    &.active {
      width: 30px;
      height: 30px;
      background: $color-success-3;
      color: $color-white;
    }
  }
}

.attachPdfDropdown {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(243, 246, 249, 0.5);
  border: 2px dashed #676c88;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin-top: 24px;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 6px 0;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 6px 0;
    span {
      text-decoration: underline;
      &.mobText {
        display: none;
      }
    }
  }
  p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
  }
}

.flexRow {
  display: flex;
  align-items: center;
  grid-gap: 4px;
}

.gridx3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.previewImg {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.fulLScreenCenter {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
