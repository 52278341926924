.moreItems {
  position: relative;
  .algoliaSearch {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 16px;
    }
    input {
      width: 100%;
      padding: 16px 16px 16px 48px;
      border: 1px solid $color-gray-1;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .algoliaHits {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    right: 0;
    border-radius: 8px;
    background: $color-white;
    padding: 16px;
    border: 1px solid $color-gray-1;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    &.active {
      opacity: 1;
      pointer-events: unset;
    }
    .legend {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray-1;
      padding-bottom: 16px;
      margin-bottom: 8px;
      p {
        font-size: 16px;
        font-weight: 500;
      }
      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .hits {
      overflow-y: auto;
      margin-right: -8px;
      max-height: 560px;
      min-height: 450px;
      @media only screen and (max-height: 800px) {
        max-height: 450px;
      }
      .hit {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 16px;
        align-items: end;
        border-bottom: 1px solid $color-gray-1;
        padding: 16px 8px 16px 0;
        margin-right: 8px;
        &:last-child {
          border-bottom: 0;
        }
        .left {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 16px;
          .hitPhoto {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }
          div {
            h2 {
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
            }
            .infoRow {
              display: flex;
              align-items: center;
              grid-gap: 8px;
              margin: 4px 0;
              color: $color-text-3;
              font-size: 14px;
              line-height: 20px;
            }
            .supplierDropdown {
              position: relative;
              &.active {
                .supplierDropdownPreview {
                  background: rgb(242, 254, 247);
                  border: 1px solid rgb(54, 190, 188);
                }
              }
              .supplierDropdownPreview {
                width: fit-content;
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 20px;
                grid-gap: 8px;
                border: 1px solid $color-gray-1;
                border-radius: 8px;
                padding: 10px 16px;
                cursor: pointer;
                p {
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 400;
                  text-transform: capitalize;
                }
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              .supplierDropdownOptions {
                position: absolute;
                top: calc(100% + 12px);
                left: 0;
                width: 650px;
                padding: 16px;
                background: $color-white;
                z-index: 2;
                border-radius: 8px;
                border: 1px solid $color-gray-1;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
                opacity: 0;
                pointer-events: none;
                transition: all 0.2s ease-in-out;
                &.active {
                  opacity: 1;
                  pointer-events: unset;
                }
                ul {
                  overflow-y: auto;
                  max-height: 300px;
                  margin-right: -8px;
                  li {
                    margin-right: 8px;
                    display: grid;
                    grid-template-columns: 40px 80px 100px 110px 1fr;
                    align-items: center;
                    border-bottom: 1px solid $color-gray-1;
                    &:last-child {
                      border-bottom: 0;
                    }
                    .supplierLogo {
                      width: 80px;
                      height: 55px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-left: 1px solid $color-gray-1;
                      border-right: 1px solid $color-gray-1;
                      padding: 2px 8px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                      }
                    }
                    .sku {
                      height: 100%;
                      border-right: 1px solid $color-gray-1;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      font-size: 14px;
                      line-height: 20px;
                      padding: 0 10px;
                    }
                    .price {
                      font-size: 16px;
                      font-weight: 500;
                      padding-left: 8px;
                    }
                    .badgeHolder {
                      border-right: 1px solid $color-gray-1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      button {
        &.active {
          background: $color-success-1;
        }
      }
    }
  }
}
