.orgPage {
  .orgPageHero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .pageTitle {
        margin-top: 4px;
      }
    }
  }

  .orgInfoBlock {
    margin: 32px 0;
    .fields {
      display: grid;
      grid-template-columns: 0.5fr repeat(3, 1fr);
      grid-gap: 16px;
    }
  }

  .orgOfficesBlock {
    margin-bottom: 32px;
  }
}

.addNewOfficeModal {
  .modalBox {
    width: 750px;
    form {
      .fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin-bottom: 16px;
      }
    }
  }
}

.updateOrgUserModal {
  .modalBox {
    width: 500px;
    form {
      &.paper {
        display: flex;
        flex-direction: column-reverse;
        box-shadow: none;
        .paperHeader {
          flex-direction: column;
          border: 0;
          padding: 0;
          h2 {
            display: none;
          }
          button {
            width: 100%;
            padding: 16px;
            font-size: 16px;
          }
        }
        .flexAlign {
          width: 100%;
        }
        .paperBody {
          padding: 0;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.inviteOrgUserModal {
  .modalBox {
    form {
      .buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
        button {
          font-size: 14px;
          padding: 12px;
          &.active {
            background: $color-black;
            color: $color-white;
          }
        }
      }
      .offices {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        margin-top: 24px;
        max-height: 300px;
        overflow-y: scroll;
        padding: 16px 16px 0 0;
        li {
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid $color-gray-1;
          padding: 12px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          &.active {
            &::after {
              background: $color-success-1;
            }
          }
          &::after {
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            border: 1px solid $color-gray-1;
            background: $color-white;
            position: absolute;
            top: -10px;
            right: -10px;
          }
          h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
          p {
            font-size: 14px;
            line-height: 20px;
            color: $color-text-3;
          }
        }
      }
    }
  }
}

.orderItemsSuppliersModal {
  .modalBox {
    .modalBody {
      display: grid;
      grid-template-columns: repeat(3, 160px);
      grid-auto-rows: 100px;
      grid-gap: 16px;
      .supplier {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border: 1px solid $color-gray-1;
        border-radius: 8px;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.orderItemsModal {
  .modalBox {
    .modalBody {
      ul {
        max-height: 400px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
        li {
          border-radius: 8px;
          border: 1px solid $color-gray-1;
          display: grid;
          grid-template-columns: 70px 1fr;
          margin-right: 8px;
          .imgBox {
            width: 100%;
            border-right: 1px solid $color-gray-1;
            height: 100%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }
          .info {
            padding: 16px;
            display: flex;
            flex-direction: column;
            grid-gap: 4px;
            a {
              font-size: 16px;
              line-height: 24px;
              text-decoration: underline;
            }
            p {
              font-size: 14px;
              line-height: 20px;
              color: $color-text-3;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.uploadCSVModal {
  .modalBox {
    width: 500px;
    .modalBody {
      .dropzone {
        border: 2px dashed $color-black;
        background: rgba(243, 246, 249, 0.5);
        height: 208px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        h4 {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          margin: 6px 0 4px 0;
        }
        p {
          font-size: 12px;
          line-height: 20px;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;
          opacity: 0;
          font-size: 0;
          border: 0;
        }
      }
      .files {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        margin-top: 20px;
        max-height: 260px;
        overflow-y: auto;
        .file {
          padding: 12px 5px 12px 0;
          display: grid;
          grid-template-columns: 1fr 35px;
          grid-gap: 5px;
          align-items: center;
          border-bottom: 1px solid $color-gray-1;
          &.invalid {
            .left {
              .info {
                p {
                  color: $color-danger;
                }
                .buffer {
                  background: $color-gray-1;
                }
              }
            }
            .right {
              .icon {
                background: $color-white;
                svg {
                  path {
                    stroke: $color-danger;
                  }
                }
              }
            }
          }
          .left {
            display: grid;
            align-items: center;
            grid-template-columns: auto 1fr;
            grid-gap: 6px;
            width: 100%;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background: $color-success-1;
            }
            .info {
              p {
                font-size: 12px;
                line-height: 20px;
              }
              span {
                color: $color-text-3;
                font-size: 12px;
                line-height: 20px;
              }
              .buffer {
                width: 100%;
                height: 6px;
                background: $color-success-2;
                border-radius: 20px;
              }
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            grid-gap: 10px;
            .icon {
              width: 26px;
              height: 26px;
              background: #c4f4d7;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $color-gray-1;
            }
            span {
              font-size: 12px;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }
      .buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin-top: 24px;
        button {
          padding: 12px;
          width: 100%;
        }
      }
    }
  }
}

.createNewOrgOrderModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: $color-white;
  display: grid;
  grid-template-rows: 70px 1fr;
  .createNewOrgOrderModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 16px;
    border-bottom: 1px solid $color-gray-1;
    svg {
      cursor: pointer;
    }
  }
  .createNewOrgOrderModalBody {
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    background: $color-gray-2;
    p.title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 400px 500px;
      align-items: start;
      grid-gap: 16px;
      @media (max-width: 1600px) {
        grid-template-columns: 1fr 300px 350px;
      }
      .column {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        .options {
          .optionItem {
            display: flex;
            align-items: center;
            grid-gap: 8px;
            border-bottom: 1px solid $color-gray-1;
            padding: 12px 0;
            cursor: pointer;
            &:last-child {
              border-bottom: 0;
            }
            .radio {
              width: 17px;
              height: 17px;
              border-radius: 50%;
              border: 1px solid $color-gray-1;
              position: relative;
              transition: all 0.2s ease-in-out;
              &.active {
                border-color: $color-success-2;
                &::after {
                  opacity: 1;
                }
              }
              &::after {
                content: '';
                position: absolute;
                top: 3px;
                left: 3px;
                right: 3px;
                bottom: 3px;
                background: $color-success-2;
                border-radius: 50%;
                opacity: 0;
                transition: all 0.2s ease-in-out;
              }
            }
            p {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
            }
            span {
              font-size: 12px;
              line-height: 20px;
              color: $color-text-3;
            }
          }
        }
      }
      .summary {
        .supplier {
          cursor: pointer;
          padding: 16px 0;
          border-bottom: 1px solid $color-gray-1;
          &:last-child {
            border-bottom: 0;
          }
          p {
            font-size: 14px;
            line-height: 20px;
            text-transform: capitalize;
          }
        }
        .details {
          margin: 16px 0;
          background: $color-gray-3;
          padding: 16px;
          border-radius: 8px;
          p {
            font-size: 14px;
            line-height: 32px;
          }
          .total {
            border-top: 1px solid $color-gray-1;
            padding-top: 8px;
            margin-top: 8px;
            p {
              font-weight: 500;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.addFormularyItemsModal {
  .modalBox {
    width: 800px;
    .modalBody {
      .algoliaHits {
        position: static;
        margin-top: 24px;
        border: 0;
        box-shadow: unset;
        padding: 0;
        .legend {
          display: none;
        }
      }
    }
  }
}
