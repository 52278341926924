.settingsPage {
  h1 {
    margin-bottom: 16px;
  }
}

.createUserModal {
  .modalBox {
    width: 750px;
    form {
      .fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin-bottom: 16px;
      }
    }
  }
}

.deleteUserModal {
  .modalBox {
    width: 420px;
    .modalBody {
      h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 24px;
        text-align: center;
      }
      .buttons {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        button {
          padding: 12px;
          font-size: 16px;
          margin-top: 16px;
        }
      }
    }
  }
}