.productsPage {
  .paperHeader {
    border-color: $color-dark;
    .productHero {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .productsSearch {
        display: flex;
        align-items: center;
        position: relative;
        .searchIcon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 12px;
          width: 16px;
          height: 16px;
          path {
            fill: $color-text-2;
          }
        }
        input {
          border: 1px solid $color-dark;
          width: 100%;
          padding: 10px 16px 10px 36px;
          border-radius: 8px;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .filtersGroup {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        width: 100%;
        & > div {
          width: 100%;
        }
      }
    }
  }
  .pagination-wrapper {
    border-top: 1px solid $color-dark;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .productsList {
    .noResults {
      text-align: center;
      padding: 32px;
      color: $color-text;
      font-size: 14px;
    }
    .productsListRow {
      display: grid;
      align-items: center;
      grid-gap: 10px;
      grid-template-columns: 2fr 1fr 1fr 110px 110px 110px 120px 90px;
      border-bottom: 1px solid $color-dark;
      &:last-child {
        border-bottom: none;
      }
      &.head {
        text-transform: uppercase;
      }
      .cell {
        padding: 10px;
        font-size: 14px;
        line-height: 20px;
        color: $color-text;

        .info {
          display: flex;
          flex-direction: column;
          p {
            font-size: 14px;
            line-height: 20px;
            color: $color-text;
          }
          span {
            font-size: 12px;
            line-height: 16px;
            color: $color-secondary;
          }
        }

        &.productName {
          display: grid;
          align-items: center;
          grid-template-columns: 45px 1fr;
          grid-gap: 8px;
          .productImg {
            width: 45px;
            height: 45px;
            mix-blend-mode: multiply;
            border: 1px solid $color-dark;
            border-radius: 8px;
          }
        }

        &.center {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
  .productFormModal {
    position: fixed;
    inset: 0;
    background: #f8f7fa;
    display: grid;
    grid-template-rows: 59px 1fr;
    .modalHero {
      border-bottom: 1px solid $color-dark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      h2 {
        font-weight: 400;
        font-size: 20px;
        color: $color-text;
      }
      .right {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        button {
          width: fit-content;
        }
      }
    }
    .modalOuter {
      padding: 16px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: grid;
      grid-template-columns: 1fr 450px;
      grid-gap: 16px;
      .leftSide,
      .rightSide {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        .paper {
          box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
          .paperHeader {
            padding: 16px 16px 8px 16px;
            border-bottom: 0;
            h2 {
              color: #5d596c;
              font-weight: 400;
              font-size: 1.125rem;
            }
          }
          .paperBody {
            padding: 16px;
            display: flex;
            flex-direction: column;
            grid-gap: 16px;
            .sku {
              display: flex;
              flex-direction: column;
              grid-gap: 16px;
              border-bottom: 1px solid $color-dark;
              padding-bottom: 16px;
              &:last-child {
                border-bottom: none;
              }
              .actionRow {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
            .fieldsX2 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 16px;
            }
            p {
              font-size: 14px;
              line-height: 20px;
              color: $color-text;
            }
            .mdxEditor {
              border: 1px solid $color-dark;
              border-radius: 8px;
            }
            .urlRow {
              display: grid;
              align-items: flex-end;
              grid-template-columns: 1fr auto;
              grid-gap: 16px;
              button {
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}
