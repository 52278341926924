.suppliersItemsGroup {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  .supplierGroup {
    background: $color-white;
    border-radius: 8px;
    border: 1px solid $color-gray-1;
    .supplierGroupHeader {
      border-bottom: 1px solid $color-gray-1;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexAlign {
        p {
          text-transform: capitalize;
        }
      }
      span {
        font-weight: 500;
      }
    }
    .supplierGroupItems {
      &.active {
        .item {
          grid-template-columns: 40px 80px 1fr;
        }
      }
      .item {
        display: grid;
        grid-template-columns: 80px 1fr;
        border-bottom: 1px solid $color-gray-1;
        &:last-child {
          border-bottom: 0;
        }
        .itemCheckbox {
          border-right: 1px solid $color-gray-1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .itemPhoto {
          border-right: 1px solid $color-gray-1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
        .itemContent {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr auto;
          padding: 16px;
          .itemDetails {
            display: flex;
            flex-direction: column;
            grid-gap: 4px;
            h5 {
              font-weight: 400;
              font-size: 14px;
              color: $color-text-3;
              font-weight: 500;
              span {
                font-weight: 400;
              }
            }
            a {
              font-size: 16px;
              line-height: 24px;
              text-decoration: underline;
            }
            span {
              font-size: 12px;
              line-height: 20px;
              color: $color-text-3;
              b {
                font-size: 14px;
                font-weight: 500;
              }
              .badge {
                display: inline-block;
                margin-left: 3px;
              }
            }
          }
          .itemActions {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            grid-gap: 8px;
            .prices {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              grid-gap: 8px;
            }
            .actions {
              display: flex;
              align-items: center;
              grid-gap: 8px;
              .rounded {
                width: 32px;
                height: 32px;
                svg {
                  width: 16px;
                  height: 16px;
                }
              }
              .price {
                width: 60px;
                font-size: 14px;
                font-weight: 500;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
