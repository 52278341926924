.select {
  &.withSearch {
    .selectPreview {
      padding: 0 !important;
      input {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 8px;
        padding: 12px 32px 12px 12px;
      }
      svg {
        position: absolute;
        right: 12px;
      }
    }
  }
  &.regular {
    position: relative;
    &.active {
      .selectPreview {
        svg {
          transform: rotate(180deg);
        }
      }
      .selectOptions {
        opacity: 1;
        pointer-events: unset;
      }
    }
    .selectPreview {
      padding: 8px 16px;
      border: 1px solid $color-gray-1;
      cursor: pointer;
      display: flex;
      align-items: center;
      grid-gap: 4px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
      font-weight: 500;
      background: $color-white;
      svg {
        width: 16px;
        height: 16px;
        transition: all 0.2s ease-in-out;
        path {
          fill: $color-text-1;
        }
      }
    }
    .selectOptions {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      right: 0;
      background: $color-white;
      z-index: 1;
      border: 1px solid $color-gray-1;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
      .noResults {
        padding: 8px;
        text-align: center;
        font-size: 14px;
      }
      ul {
        max-height: 260px;
        overflow-y: auto;
        &.alignLeft {
          li {
            text-align: left;
            padding: 8px 16px;
          }
        }
        li {
          padding: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          border-bottom: 1px solid $color-gray-1;
          transition: all 0.2s ease-in-out;
          text-align: center;
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
          &:hover {
            background: $color-gray-2;
          }
          &.active {
            background: rgba(213, 251, 229, 0.48);
          }
        }
      }
    }
  }
  &.withSearchInMenu {
    .searchField {
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        width: 16px;
        height: 16px;
      }
      input {
        width: 100%;
        padding: 8px 12px 8px 32px;
        border-radius: 8px;
        border: 1px solid $color-gray-1;
      }
    }
    .noResults {
      text-align: center;
      font-size: 12px;
      padding: 5px;
    }
  }
  &.gray {
    .selectPreview {
      border: 0;
      input {
        background: $color-gray-3;
      }
    }
  }
}
