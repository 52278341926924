.ordersPage {
  .pageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      width: fit-content;
      padding: 10px 14px;
    }
  }

  .filters {
    margin: 24px 0;
    .buttons {
      display: flex;
      align-items: center;
      grid-gap: 12px;
      .datePicker {
        .selected {
          border: 1px solid $color-gray-1;
          background: $color-white;
          border-radius: 8px;
          padding: 8px 16px;
          text-align: center;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .regular {
        .selectPreview {
          padding: 8px 16px;
          background: $color-white;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          text-transform: capitalize;
        }
        .selectOptions {
          width: 200px;
          ul {
            li {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .orderStatus {
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
  }

  .orderDetailsContainer {
    padding: 10px;
    background: $color-white;
    .group {
      .groupName {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-transform: capitalize;
        margin-left: 10px;
      }
      .groupBody {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        margin: 8px 0;
        .itemsTable {
          .row {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 2fr 1fr 1fr 100px 100px 80px 100px;
            grid-gap: 10px;
            padding: 0 10px;
            .tableCell {
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.04em;
              text-transform: uppercase;
              padding: 10px 0;
              color: $color-text-3;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 500;
            }
          }
          .itemsTableHead {
            .tableCell {
              padding-bottom: 0;
              font-weight: 600;
            }
          }
          .itemsTableBody {
            border: 1px solid $color-gray-1;
            border-bottom: 0;
            .row {
              border-bottom: 1px solid $color-gray-1;
              .tableCell {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
}

.ordersDatePicker {
  .modalBox {
    width: 500px;
    .react-calendar {
      width: 100%;
      margin-bottom: 16px;
      border-radius: 8px;
      border: 1px solid $color-gray-1;
    }
  }
}
