.healthPage {
  .healthSelects {
    display: flex;
    gap: 18px;

    .group {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .select {
        min-width: 300px;
      }
    }
  }

  .cols {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .snapshotsCalendar {
      position: relative;
      width: 550px;
      height: 272px;

      &.active {
        .overlay {
          display: none;
        }
      }

      .overlay {
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(2px);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .react-calendar {
        width: 100%;
        margin-bottom: 16px;
        border-radius: 8px;
        border: 1px solid $color-gray-1;

        .react-calendar__tile {
          border-radius: 6px;
        }

        .react-calendar__tile--active {
          background-color: $color-gray-4;
          color: black;

          &.hasSnapshots {
            background-color: $color-success-2;
          }
        }

        .hasSnapshots {
          background-color: $color-success-1;
        }
      }
    }

    .selectedPreview {
      padding: 12px;
      height: 272px;
      width: 300px;

      p {
        font-size: 18px;
        margin-bottom: 12px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        a {
          font-size: 16px;
        }
      }
    }
    .lastTen {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: fit-content;
      max-width: 550px;

      h3 {
        font-weight: 500;
      }

      .lists {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .group {
          border: 1px solid $color-gray-1;
          border-radius: 8px;
          p {
            border-bottom: 1px solid $color-gray-1;
            font-size: 14px;
            line-height: 20px;
            padding: 8px;
          }
          ul {
            font-size: 14px;
            line-height: 20px;
            padding: 8px;
          }
        }
      }
    }

    .pieChart {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 550px;

      .piechartTooltip {
        padding: 16px;
        display: flex;
        gap: 6px;
        background-color: $color-gray-2;
        p:first-child {
          text-transform: capitalize;
        }
      }

      .chartLegend {
        display: flex;
        flex-direction: column;
        gap: 12px;

        li {
          display: flex;
          align-items: center;
          gap: 8px;
          button {
            all: unset;
            cursor: pointer;
            &:disabled {
              all: unset;
            }
          }

          p {
            text-transform: capitalize;
          }
          .box {
            width: 18px;
            height: 18px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
