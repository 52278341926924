.checkbox {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  background: $color-white;
  border: 1px solid rgb(182, 185, 198);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background: $color-success-2;
    border-color: $color-success-2;
    svg {
      opacity: 1;
    }
  }
  svg {
    width: 100%;
    height: 100%;
    opacity: 0;
    path {
      fill: $color-white;
    }
  }
}
