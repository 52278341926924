.manufacturersPage{
  .codeWrapper {
    height: 600px;
    border: 1px solid #ccc;
    overflow: auto;
    margin: 5px;
  }
  .Hero {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }

  .confirmModal {
    position: fixed;
    inset: 0;
    background: white;

    .confirmHero {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid #ccc;
      width: 100%;
      h2 {
        font-weight: 400;
        font-size: 20px;
        color: #333;
      }
      .right {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        button {
          width: fit-content;
        }
      }
    }

    .confirmContent {
      padding: 16px;
      width: 100%;
      height: 95%;
      overflow-y: auto;
      
      .diffSection {
        margin-bottom: 16px;
      }
      
      .errors {
        background-color: #FFC1C3;
        border: 1px solid #FFC1C3;
        padding-left: 32px;

        li {
          list-style-type: square;
        }
      }
    }
  }
}