.modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: $color-black, $alpha: 0.48);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .modalBox {
    border: 1px solid $color-gray-1;
    background: $color-white;
    border-radius: 8px;
    min-width: 400px;
    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray-1;
      padding: 16px;
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: $color-text-1;
      }
      svg {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          path {
            fill: $color-black;
          }
        }
      }
    }
    .modalBody {
      padding: 16px;
    }
  }
}

.addCredentialsModal {
  .modalBody {
    padding: 0 !important;
  }
  .modalBox {
    .scrollable {
      min-width: 600px;
      max-height: 650px;
      padding: 16px;

      &.active {
        overflow-y: auto;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: $color-gray-3;
        border-radius: 16px;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-gray-4;
        border-radius: 16px;
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    .submitBtn {
      max-width: 95%;
      margin: 16px auto;
    }

    h3 {
      text-align: center;
      margin: 12px 0;
    }
    .col {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    .repList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .repPaper {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .row {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              background-color: $color-success-1;
              padding: 3px;
              border-radius: 6px;
              font-size: 12px;
            }
          }
        }

        .actionMenuWrapper {
          position: relative;

          svg {
            cursor: pointer;
          }

          .actionMenu {
            position: absolute;
            background-color: white;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
            top: 100%;
            right: 0;
            padding: 6px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            min-width: 200px;
            z-index: 999999;
            li {
              gap: 6px;
              font-size: 14px;
              padding: 8px 12px;
              cursor: pointer;
              border-radius: 6px;
              &:hover {
                background-color: #f3f6f9;
              }
              &.delete {
                color: $color-danger;
              }
            }
          }
        }
      }
    }

    .buttons-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 14px;
    }

    .special-label {
      display: none !important;
    }

    .addNew {
      padding: 8px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.selectPaymentModal {
  .modalBox {
    width: 600px;
    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid $color-gray-1;
      padding-bottom: 16px;
      button {
        background: transparent;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding: 8px 16px;
        border-radius: 8px;
        &.active {
          background: $color-success-1;
        }
      }
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
      margin-bottom: 16px;
      max-height: 450px;
      overflow-y: auto;
      .card {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $color-gray-1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        cursor: pointer;
        background: $color-gray-1;
        &.visa {
          background: rgb(245, 248, 251);
          border: 1px solid rgba(0, 87, 159, 0.12);
        }
        &.mastercard {
          background: rgb(255, 249, 240);
          border: 1px solid rgba(255, 153, 0, 0.12);
        }
        &.active {
          border-color: $color-black;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
    .checkForm {
      .fakeField {
        width: 100%;
        min-height: 40px;
        background: $color-gray-3;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;
        padding: 10px 16px;
        cursor: pointer;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      button {
        margin-top: 24px;
      }
    }
  }
}

.createCsOverrideModal {
  .modalBox {
    width: 500px;
    .modalBody {
      form {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        .switchBtn {
          justify-content: space-between;
          p {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.changeOrderStatusModal {
  .modalBox {
    width: 500px;
    .modalBody {
      form {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        .selectPreview {
          justify-content: space-between;
          text-transform: capitalize;
        }
        .selectOptions {
          ul {
            li {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.shipmentHistoryModal {
  .modalBox {
    width: 1300px;
  }
}

.declineDraftOrder {
  .modalBox {
    width: 500px;
    form {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
    }
  }
}

.promotionModal {
  .modalBox {
    width: 500px;
    form {
      .select {
        .selectPreview {
          justify-content: space-between;
        }
      }
      .fakeField {
        width: 100%;
        min-height: 40px;
        background: $color-gray-3;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;
        padding: 10px 16px;
        cursor: pointer;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      button[type='submit'] {
        margin-top: 32px;
      }
    }
  }
}

.addPromotionsModal {
  .modalBox {
    width: 1000px;
    .algoliaSearch {
      display: flex;
      align-items: center;
      position: relative;
      svg {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 16px;
      }
      input {
        width: 100%;
        padding: 16px 16px 16px 48px;
        border: 1px solid $color-gray-1;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    h6 {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }
    .hits {
      height: 600px;
      overflow-y: auto;
      margin-top: 24px;
      padding: 8px;
      .hitsWrapper {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
      }
      .hit {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        grid-gap: 16px;
        border-bottom: 1px solid $color-gray-1;
        padding: 8px 0;
        .left {
          display: flex;
          align-items: center;
          grid-gap: 8px;
          .img {
            width: 55px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }
          h3 {
            font-weight: 400;
            font-size: 14px;
          }
        }
        .suppliers {
          position: relative;
          .suppliersPreview {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 16px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid $color-gray-1;
            padding: 12px 16px;
            cursor: pointer;
            p {
              font-size: 14px;
            }
          }
          ul {
            position: absolute;
            right: 0;
            width: 320px;
            top: calc(100% + 4px);
            background: $color-white;
            border-radius: 8px;
            border: 1px solid $color-gray-1;
            z-index: 9;
            overflow: hidden;
            li {
              display: grid;
              align-items: center;
              grid-template-columns: 1fr auto;
              grid-gap: 16px;
              padding: 8px 16px;
              border-bottom: 1px solid $color-gray-1;
              &:last-child {
                border-bottom: 0;
              }
              cursor: pointer;
              &:hover {
                background: $color-gray-3;
              }
              .supplierInfo {
                display: flex;
                flex-direction: column;
                grid-gap: 4px;
                span {
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}

.updateShipmentModal {
  .selectPreview {
    justify-content: space-between;
  }
  .fakeField {
    width: 100%;
    min-height: 40px;
    background: $color-gray-3;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
}

.additionalTrackingNumbers {
  .formikRow {
    display: grid;
    align-items: flex-end;
    gap: 24px;
    grid-template-columns: 400px 250px 250px auto;
  }
}

.singleJobModal {
  a {
    margin-top: 24px;
  }
}

.retrieveCredentialsModal {
  &.hasData {
    .modalBody {
      overflow-y: auto;
    }
  }

  &.notPopulated {
    .modalBody {
      overflow: visible !important;
    }
  }
  .modalBody {
    max-height: 500px;
    min-width: 600px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: $color-gray-3;
      border-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-gray-4;
      border-radius: 16px;
    }

    .group {
      margin-bottom: 12px;
    }

    .selects {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .dataContainer {
      margin-bottom: 18px;

      .deleteCredentialsBtn {
        margin-bottom: 12px;
        width: 300px;
        margin-inline: auto;
      }
      .row {
        display: flex;
        align-items: center;
        gap: 12px;

        button {
          width: fit-content;
        }

        svg {
          cursor: pointer;
        }
      }

      .credentials {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .row {
          display: flex;
          align-items: center;
          gap: 12px;

          svg {
            cursor: pointer;
          }
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .gpoTab {
        .name {
          margin-bottom: 12px;
        }
        margin-bottom: 16px;
      }

      .repPaper {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .row {
            display: flex;
            align-items: center;
            gap: 12px;

            svg {
              cursor: pointer;
            }
          }
        }

        .actionMenuWrapper {
          position: relative;

          svg {
            cursor: pointer;
          }

          .actionMenu {
            position: absolute;
            background-color: white;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
            top: 100%;
            right: 0;
            padding: 6px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            min-width: 200px;
            z-index: 999999;
            li {
              gap: 6px;
              font-size: 14px;
              padding: 8px 12px;
              cursor: pointer;
              border-radius: 6px;
              &:hover {
                background-color: #f3f6f9;
              }
              &.delete {
                color: $color-danger;
              }
            }
          }
        }
      }
    }
  }
}
