.flexBetween {
  margin-bottom: 24px;
}
.userImpersonateModal {
  .modalBox {
    width: 600px;
    .modalBody {
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        .group {
          &.up {
            margin-top: -16px;
          }
          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .radio-group {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
        .radio-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 4px;
          &.active {
            .radio {
              border: 1px solid $color-success-3;
              div {
                background: $color-success-3;
              }
            }
          }
          .radio {
            width: 20px;
            height: 20px;
            border: 1px solid black;
            border-radius: 50%;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              border-radius: 50%;
              width: 100%;
              height: 100%;
            }
          }
          label {
            padding: 0;
          }
        }
      }
    }
  }
}

.createUserModal {
  .modalBox {
    width: 600px;
    .modalBody {
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        button {
          margin-top: 40px;
        }
      }
    }
  }
}
