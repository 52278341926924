.orderPage {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  .orderNote {
    form {
      button {
        margin-top: 16px;
        width: fit-content;
      }
    }
  }

  .orderMissingItemsSection {
    .paperBody {
      .groupWrap {
        border: 1px solid $color-gray-1;
        border-radius: 8px;
        overflow: hidden;
        .group {
          &:last-child {
            .groupHead {
              border-bottom-width: 0;
            }
          }
          &.active {
            .groupHead {
              border-bottom-width: 1px;
            }
            .groupHead {
              svg {
                transform: rotate(180deg);
              }
            }
            .groupBody {
              display: block;
            }
          }
          .groupHead {
            padding: 12px 16px;
            border-bottom: 1px solid $color-gray-1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $color-gray-2;
            cursor: pointer;
            p {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              text-transform: capitalize;
              span {
                color: $color-text-3;
              }
            }
            svg {
              width: 20px;
              height: 20px;
              transition: all 0.2s ease-in-out;
              path {
                fill: $color-text-3;
              }
            }
          }
          .groupBody {
            display: none;
            .groupBodyItem {
              position: relative;
              border-bottom: 1px solid $color-gray-1;
              padding: 16px;
              &:last-child {
                border-bottom: 0;
              }
              .badge {
                position: absolute;
                top: 16px;
                right: 16px;
              }
              h2 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                margin-bottom: 12px;
              }
              .info {
                display: grid;
                grid-template-columns: repeat(4, auto);
                align-items: center;
                border: 1px solid $color-gray-1;
                border-radius: 8px;
                width: fit-content;
                p {
                  font-size: 14px;
                  line-height: 20px;
                  border-right: 1px solid $color-gray-1;
                  padding: 6px 16px;
                  &:last-child {
                    border-right: 0;
                  }
                  span {
                    margin-right: 5px;
                    font-weight: 500;
                    color: $color-text-3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.editOrderModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-white;
  display: grid;
  grid-template-rows: 68px 1fr;
  .editModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $color-gray-1;
    h2 {
      font-size: 24px;
      font-weight: 400;
      span {
        font-weight: 500;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        path {
          fill: $color-text-1;
        }
      }
    }
  }
  .editModalBody {
    background: $color-gray-2;
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 400px;
    align-items: start;
    grid-gap: 24px;
    .leftSide {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
      .paperBody {
        label {
          padding-top: 0;
        }
        textarea {
          background: $color-gray-3;
        }
      }
      .summary {
        border: 1px solid $color-gray-1;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        background: $color-white;
        padding: 16px;
        .summaryHeader {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          padding-bottom: 16px;
          border-bottom: 1px solid $color-gray-1;
        }
        .summarySuppliers {
          .flexBetween {
            padding: 16px 0;
            border-bottom: 1px solid $color-gray-1;
            .flexAlign {
              grid-gap: 8px;
              font-size: 14px;
              line-height: 20px;
              text-transform: capitalize;
            }
            span {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
            }
          }
        }
        .summaryDetails {
          background: $color-gray-3;
          border-radius: 8px;
          margin: 16px 0;
          padding: 16px;
          .flexBetween {
            font-size: 14px;
            line-height: 24px;
            &.total {
              border-top: 1px solid $color-gray-1;
              font-weight: 500;
              font-size: 18px;
              line-height: normal;
              padding-top: 16px;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}

.editOrderItemPriceModal {
  .modalBox {
    width: 500px;
    .productCard {
      border: 1px solid $color-gray-1;
      border-radius: 8px;
      display: grid;
      grid-template-columns: 80px 1fr;
      margin-bottom: 12px;
      .productCardPhoto {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $color-gray-1;
        padding: 5px;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
      .productCardDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px;
        grid-gap: 4px;
        p {
          font-size: 16px;
          line-height: 24px;
        }
        span {
          font-size: 12px;
          line-height: 20px;
          color: $color-text-3;
          b {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    form {
      button {
        margin-top: 16px;
      }
    }
  }
}

.fullScreenModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: $color-white;
  display: grid;
  grid-template-rows: 70px 1fr 90px;
  &__Header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid $color-gray-1;
    background: $color-white;
    svg {
      cursor: pointer;
    }
  }
  &__Body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    &__Content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      grid-gap: 32px;
      margin: 32px 0;
      padding: 0 40px;
      .paper {
        .paperBody {
          &.gridX2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
            .pdfViewer {
              border: 1px solid $color-gray-1;
              border-radius: 8px;
              height: 610px;
              overflow-y: auto;
              padding: 16px;
              margin-bottom: 16px;
            }
          }
          .select {
            .selectPreview {
              justify-content: space-between;
              padding: 10px 16px;
              background: $color-gray-3;
              font-size: 14px;
              line-height: 20px;
              border: 0;
            }
            .selectOptions {
              ul {
                li {
                  text-align: left;
                  text-transform: capitalize;
                }
              }
            }
          }
          .addNewRow {
            display: grid;
            grid-template-columns: 1fr 1fr 30px;
            align-items: end;
            grid-gap: 16px;
            .rounded {
              margin-bottom: 4px;
            }
          }
          .fakeField {
            width: 100%;
            background: $color-gray-3;
            font-size: 14px;
            line-height: 20px;
            border-radius: 8px;
            padding: 10px 16px;
            cursor: pointer;
            font-weight: 600;
            display: flex;
            align-items: center;
          }
        }
      }
      .fullScreenItemsTables {
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
        .fullScreenItemsGroup {
          .head {
            display: flex;
            align-items: center;
            grid-gap: 24px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
  &__Footer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;
    box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.04);
    background: $color-white;
    padding: 0 40px;
    .sum {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
      }
    }
    button {
      &.contained {
        width: fit-content;
      }
    }
  }
}

.orderFullScreenModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: $color-white;
  padding: 24px;
  padding-bottom: 80px;
  overflow-y: auto;
  .closeIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .gridX2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    align-items: flex-start;
    .pdfViewer {
      border: 1px solid $color-gray-1;
      border-radius: 8px;
      height: 500px;
      overflow-y: auto;
      padding: 16px;
      margin-bottom: 16px;
    }
  }
  form {
    .fielsGroup {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      .suppliersSelect {
        li {
          display: flex;
          align-items: center;
          grid-gap: 8px;
        }
      }
    }
    .addNewRow {
      display: grid;
      grid-template-columns: 1fr 1fr 30px;
      align-items: end;
      grid-gap: 16px;
      .rounded {
        margin-bottom: 4px;
      }
    }
    .x2Fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
    .fakeField {
      width: 100%;
      background: $color-gray-3;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
      padding: 10px 16px;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    .select {
      .selectPreview {
        display: grid;
        grid-template-columns: 1fr 20px;
        align-items: center;
        grid-gap: 16px;
        padding: 10px 16px;
        background: $color-gray-3;
        font-size: 14px;
        line-height: 20px;
        border: 0;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .selectOptions {
        ul {
          li {
            text-align: left;
            text-transform: capitalize;
          }
        }
      }
    }
    .submitBtnHolder {
      position: fixed;
      bottom: 0;
      z-index: 999;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 20px;
      background: $color-white;
      box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.04);
      button {
        width: fit-content;
        padding: 12px;
        font-size: 14px;
      }
    }
  }
}
